import { ProgramScheduleAll } from '@/interfaces/ProgramSchedule';
import { ProgramTypeProps, ProgramTypeV2Properties } from '@/interfaces/ProgramType';
import { SelectOptions } from '@/interfaces/RegistrationForm';
import { Testimony } from '@/interfaces/Testimony';

export const programTypeNew : ProgramTypeV2Properties[]=[
    {
        id: 1,
        typeCourse:'Free Course',
        typeCourseDescription:'Introduction to IT Programming: Unleashing your Coding Potential',
        stack: 'Video Game',
        totalPrice: 'FREE!',
        packageDescription:'1 Jam Paham Buat 2D Game via Unity Engine!',
        discountPrice:'1 JUTA!'
    },
    {
        id: 2,
        typeCourse:'Free Course',
        typeCourseDescription:'Introduction to IT Programming: Unleashing your Coding Potential',
        stack: 'Front-End Website',
        totalPrice: 'FREE!',
        packageDescription:'1 Jam Paham Buat Front-End Web via Coding!',
        discountPrice:'1 JUTA!'
    },
    {
        id: 3,
        typeCourse:'Free Course',
        typeCourseDescription:'Introduction to IT Programming: Unleashing your Coding Potential',
        stack: 'Back-End Website',
        totalPrice: 'FREE!',
        packageDescription:'1 Jam Paham Web API terintegrasi via Coding!',
        discountPrice:'1 JUTA!'
    },
    {
        id: 4,
        typeCourse:'Free Course',
        typeCourseDescription:'Introduction to IT Programming: Unleashing your Coding Potential',
        stack: 'Mobile Application',
        totalPrice: 'FREE!',
        packageDescription:'1 Jam Paham Buat Mobile Application via Coding!',
        discountPrice:'1 JUTA!'
    },
    {
        id: 5,
        typeCourse:'Workshop',
        typeCourseDescription:'Coding Kickstart: An Interactive Workshop for IT Programmer Beginners',
        stack: 'Video Game',
        totalPrice:'Rp 560.000',
        packageDescription:'2 Hari Dibimbing Buat 2D Game via Coding Pertamamu!',
        packageSubdescription:'Belajar coding + project video game? Workshop Murah rasa Bootcamp?!',
        discountPrice:'6 JUTA!'
    },
    {
        id: 6,
        typeCourse:'Workshop',
        typeCourseDescription:'Coding Kickstart: An Interactive Workshop for IT Programmer Beginners',
        stack: 'Front-End Website',
        totalPrice:'Rp 280.000',
        packageDescription:'2 Hari Dibimbing Buat Front-End Web via Coding Pertamamu!',
        packageSubdescription:'Belajar coding + project front-end website? Workshop Murah rasa Bootcamp?!',
        discountPrice:'2 JUTA!'
    },
    {
        id: 7,
        typeCourse:'Workshop',
        typeCourseDescription:'Coding Kickstart: An Interactive Workshop for IT Programmer Beginners',
        stack: 'Back-End Website',
        totalPrice:'Rp 560.000',
        packageDescription:'2 Hari Dibimbing Buat Web API interaktif Pertamamu!',
        packageSubdescription:'Belajar coding + project back-end website? Workshop Murah rasa Bootcamp?!',
        discountPrice:'6 JUTA!'
    },
    {
        id: 8,
        typeCourse:'Workshop',
        typeCourseDescription:'Coding Kickstart: An Interactive Workshop for IT Programmer Beginners',
        stack: 'Mobile Application',
        totalPrice:'Rp 560.000',
        packageDescription:'2 Hari Dibimbing Buat Mobile Application (Android & iOS) Pertamamu!',
        packageSubdescription:'Belajar coding + project mobile app? Workshop Murah rasa Bootcamp?!',
        discountPrice:'7 JUTA!'
    },
    {
        id: 9,
        typeCourse:'Bootcamp',
        typeCourseDescription:'From Zero to IT Hero: A Beginner\'s Guide to IT Development',
        stack: 'Full Stack Engineer Website and Mobile Application',
        totalPrice:'Rp 8.850.000',
        packageDescription:'8 Minggu (320 jam belajar) Jadi Full Stack Engineer',
        packageSubdescription:'',
        discountPrice: '',
    },
]

export const programType: ProgramTypeProps[] = [
    {
        id: 1,
        packageType: 'Standard',
        stack: 'Backend',
        totalPrice: 5200000,
        classDuration: '4 Bulan',
        discountPrice: null,
    },
    {
        id: 2,
        packageType: 'Standard',
        stack: 'Front-End',
        totalPrice: 5200000,
        classDuration: '4 Bulan',
        discountPrice: null,
    },
    {
        id: 3,
        packageType: 'Standard',
        stack: 'Front-End Mobile',
        totalPrice: 5200000,
        classDuration: '4 Bulan',
        discountPrice: null,
    },
    {
        id: 4,
        packageType: 'Hemat',
        stack: 'Fullstack Web',
        totalPrice: 9600000,
        classDuration: '4 Bulan',
        discountPrice: '800 ribu!',
    },
    {
        id: 5,
        packageType: 'Hemat',
        stack: 'Fullstack Mobile',
        totalPrice: 9600000,
        classDuration: '4 Bulan',
        discountPrice: '800 ribu!',
    },
    {
        id: 6,
        packageType: 'Lengkap',
        stack: 'Fullstack Web & Mobile',
        totalPrice: 13600000,
        classDuration: '4 Bulan',
        discountPrice: '2 juta!',
    },
];

export const programSchedule: ProgramScheduleAll = {
    standard: [
        {
            id: 1,
            packageType: 'Standard',
            stack: 'Backend',
            days: 'Senin - Jumat',
            time: '09.00-12.00 WIB',
        },
        {
            id: 2,
            packageType: 'Standard',
            stack: 'Front-End',
            days: 'Senin - Jumat',
            time: '09.00-12.00 WIB',
        },
        {
            id: 3,
            packageType: 'Standard',
            stack: 'Front-End Mobile',
            days: 'Senin - Jumat',
            time: '09.00-12.00 WIB',
        },
    ],
    economic: [
        {
            id: 4,
            packageType: 'Hemat',
            stack: 'Fullstack Web',
            days: 'Senin - Jumat',
            time: '09.00-14.00 WIB',
        },
        {
            id: 5,
            packageType: 'Hemat',
            stack: 'Fullstack Mobile',
            days: 'Senin - Jumat',
            time: '09.00-14.00 WIB',
        },
    ],
    full: [
        {
            id: 6,
            packageType: 'Lengkap',
            stack: 'Fullstack Web & Mobile',
            days: 'Senin - Jumat',
            time: '09.00-17.00 WIB',
        },
    ],
};

export const documentations = [
    {
        src: 'img/home/documentations-2.jpeg',
        description: 'Seleksi Peserta Internship Batch 1 Tahun 2017',
    },
    {
        src: 'img/home/documentations-3.jpg',
        description: 'Acara Closing Internship Batch 3 Tahun 2018',
    },
    {
        src: 'img/home/documentations-1.jpg',
        description: 'Training Offline Batch 3 Tahun 2019',
    },
    {
        src: 'img/home/documentations-6.png',
        description: 'Training Batch 5 Tahun 2021 Pemrograman',
    },
    {
        src: 'img/home/documentations-4.png',
        description: 'Training Batch 5 Tahun 2021 Scaling Traditional SQL',
    },
    {
        src: 'img/home/documentations-5.png',
        description: 'Training Batch 5 Tahun 2021 Performance Test Using JMeter',
    },
];

export const listCurrentStatus: SelectOptions<string>[] = [
    {
        label: 'Mahasiswa',
        value: 'Mahasiswa',
    },
    {
        label: 'Sedang Mencari Kerja',
        value: 'Sedang Mencari Kerja',
    },
    {
        label: 'Sekolah Aktif',
        value: 'Sekolah Aktif',
    },
    {
        label: 'Pelaku Usaha',
        value: 'Pelaku Usaha',
    },
    {
        label: 'Freelancer',
        value: 'Freelancer',
    },
    {
        label: 'Lainnya',
        value: 'Lainnya'
    }
]

export const listReasonToJoinProgram: SelectOptions<string>[] = [
    {
        label: 'Ingin Belajar dan Dapat Kerja',
        value: 'Ingin Belajar dan Dapat Kerja',
    },
    {
        label: 'Ingin Belajar dengan Bimbingan Mentor',
        value: 'Ingin Belajar dengan Bimbingan Mentor',
    },
    {
        label: 'Ingin Mendapatkan Ilmu Baru',
        value: 'Ingin Mendapatkan Ilmu Baru',
    },
    {
        label: 'Ingin Dapat Kerja',
        value: 'Ingin Dapat Kerja',
    },
    {
        label: 'Lainnya',
        value: 'Lainnya'
    }
]

export const hasLaptopOptions: SelectOptions<string>[] = [
    { label: 'Ya', value: 'Ya' },
    { label: 'Tidak', value: 'Tidak' }
]

export const informationSourceList: SelectOptions<string>[] = [
    { label: 'WhatsApp', value: 'WhatsApp' },
    { label: 'Telegram', value: 'Telegram' },
    { label: 'Instagram', value: 'Instagram' },
    { label: 'Facebook', value: 'Facebook' },
    { label: 'Website', value: 'Website' },
    { label: 'LinkedIn', value: 'LinkedIn' },
    { label: 'Referensi Teman', value: 'Referensi Teman' },
    { label: 'Lainnya', value: 'Lainnya' },
]

export const holdingPartnersImageSrc: string[] = [
    'img/home/agit.png',
    'img/home/astra-isuzu.png',
    'img/home/astra-international.png',
    'img/home/baker-mckenzie.png',
    'img/home/bca-life.png',
    'img/home/binus-creates.png',
    'img/home/bmkg.png',
    'img/home/cas-destination.png',
    'img/home/gapura.png',
    'img/home/coca-cola.png',
    'img/home/manulife.png',
    'img/home/sera.png',
    'img/home/trac.png',
    'img/home/pt-surya-madistrindo.png',
    'img/home/sosro.png',
    'img/home/toyota-tsusho.png',
    'img/home/toyota-astra-motor.png',
    'img/home/zurich.png',
];

export const testimony: Testimony[] = [
    {
        id: 1,
        name: 'Fiona Varencia',
        batch: '7',
        tetimony:
            '“Selama melakukan kegiatan magang di Accelist, saya terlibat dalam proyek menarik dan belajar banyak dari rekan-rekan berpengalaman. Meskipun ada tekanan, tantangan tersebut membantu saya tumbuh dan belajar menghadapi situasi yang menantang. Terima kasih kepada seluruh tim Accelist atas dukungan dan pengalaman yang luar biasa.”',
        imageSrc: 'img/home/testimoni-1.jpeg',
    },
    {
        id: 2,
        name: 'Ryan Frederick',
        batch: '7',
        tetimony:
            '“ Training di Accelist menurutku sangat membantu untuk memahami teknik pemrograman yang digunakan di perusahaan, karena selama kuliah sulit mencari real case untuk diterapkan di dunia kerja dan waktu baru masuk bingung banget apa yang perlu dipelajari, mulai darimana. Tapi dari tim dan atasan Accelist, kami di training dari awal mulai dari hal ter-basic menggunakan Visual Studio, C#, HTML, CSS, sampai ke hal yang cukup advanced seperti metode MVC, validasi, injection, yang akhirnya membuat saya mampu membuat API dan web ketika lepas dari training tersebut.”',
        imageSrc: 'img/home/testimoni-5.jpeg',
    },
    {
        id: 3,
        name: 'Revin Hermawan',
        batch: '7',
        tetimony:
            '"Program Magang di Accelist berkesan sekali, projek - projeknya melatih dan meningkatkan skill saya baik softskill maupun hardskill."',
        imageSrc: 'img/home/testimoni-2.jpeg',
    },
    {
        id: 4,
        name: 'Gabrielle Angelica',
        batch: '7',
        tetimony:
            '“Belajar IT di Accelist recommended banget, dari belum ngerti apa-apa, sampai akhirnya punya skill yang berguna untuk di dunia perkuliahan maupun pekerjaan! Materi dan metode yang diajarkan juga mudah dimengerti.”',
        imageSrc: 'img/home/testimoni-3.jpeg',
    },
    {
        id: 5,
        name: 'Lyvia Cornelia',
        batch: '7',
        tetimony:
            '“Saya sangat senang bisa mendapatkan pengalaman training di Accelist, berkat Accelist saya dapat memahami ilmu-ilmu pemrograman dari awal yang belum pernah saya pelajari sebelumnya di kampus.”',
        imageSrc: 'img/home/testimoni-4.jpeg',
    },
];

export const ColorPalette = {
    primaryBlack100: '#1E293B',
    primaryBlack80: '#4B5462',
    primaryBlack60: '#787F89',
    primaryBlack30: '#BCBFC5',
    primaryBlack10: '#E9EAEC',
    primaryGreen100: '#0D9488',
    primaryGreen80: '#3DA9A0',
    primaryGreen60: '#6EBFB8',
    primaryGreen30: '#B7DFDC',
    primaryGreen10: '#E7F5F4',
    primaryWhite: '#F8FAFC',
    alertPrimary: '#1D93FF',
    alertDanger: '#CE3636',
    alertSuccess: '#0D9488',
    alertWarning: '#F5B307',
};

export const indonesiaRegion = [
    {
        provinsi: "Aceh",
        kota: [
            "Kota Banda Aceh",
            "Kota Sabang",
            "Kota Lhokseumawe",
            "Kota Langsa",
            "Kota Subulussalam",
            "Kab. Aceh Selatan",
            "Kab. Aceh Tenggara",
            "Kab. Aceh Timur",
            "Kab. Aceh Tengah",
            "Kab. Aceh Barat",
            "Kab. Aceh Besar",
            "Kab. Pidie",
            "Kab. Aceh Utara",
            "Kab. Simeulue",
            "Kab. Aceh Singkil",
            "Kab. Bireun",
            "Kab. Aceh Barat Daya",
            "Kab. Gayo Lues",
            "Kab. Aceh Jaya",
            "Kab. Nagan Raya",
            "Kab. Aceh Tamiang",
            "Kab. Bener Meriah",
            "Kab. Pidie Jaya"
        ]
    },
    {
        provinsi: "Sumatera Utara",
        kota: [
            "Kota Medan",
            "Kota Pematang Siantar",
            "Kota Sibolga",
            "Kota Tanjung Balai",
            "Kota Binjai",
            "Kota Tebing Tinggi",
            "Kota Padang Sidempuan",
            "Kota Gunung Sitoli",
            "Kab. Serdang Bedagai",
            "Kab. Samosir ",
            "Kab. Humbang Hasundutan",
            "Kab. Pakpak Bharat",
            "Kab. Nias Selatan",
            "Kab. Mandailing Natal",
            "Kab. Toba Samosir",
            "Kab. Dairi",
            "Kab. Labuhan Batu",
            "Kab. Asahan",
            "Kab. Simalungun",
            "Kab. Deli Serdang",
            "Kab. Karo",
            "Kab. Langkat",
            "Kab. Nias",
            "Kab. Tapanuli Selatan",
            "Kab. Tapanuli Utara",
            "Kab. Tapanuli Tengah",
            "Kab. Batu Bara",
            "Kab. Padang Lawas Utara",
            "Kab. Padang Lawas",
            "Kab. Labuhanbatu Selatan",
            "Kab. Labuhanbatu Utara",
            "Kab. Nias Utara",
            "Kab. Nias Barat"
        ]
    },
    {
        provinsi: "Sumatera Barat",
        kota: [
            "Kota Padang",
            "Kota Solok",
            "Kota Sawhlunto",
            "Kota Padang Panjang",
            "Kota Bukittinggi",
            "Kota Payakumbuh",
            "Kota Pariaman",
            "Kab. Pasaman Barat",
            "Kab. Solok Selatan",
            "Kab. Dharmasraya",
            "Kab. Kepulauan Mentawai",
            "Kab. Pasaman",
            "Kab. Lima Puluh Kota",
            "Kab. Agam",
            "Kab. Padang Pariaman",
            "Kab. Tanah Datar",
            "Kab. Sijunjung",
            "Kab. Solok",
            "Kab. Pesisir Selatan"
        ]
    },
    {
        provinsi: "Riau",
        kota: [
            "Kota Pekan Baru",
            "Kota Dumai",
            "Kab. Kepulauan Meranti",
            "Kab. Kuantan Singingi",
            "Kab. Siak",
            "Kab. Rokan Hilir",
            "Kab. Rokan Hulu",
            "Kab. Pelalawan",
            "Kab. Indragiri Hilir",
            "Kab. Bengkalis",
            "Kab. Indragiri Hulu",
            "Kab. Kampar"
        ]
    },
    {
        provinsi: "Jambi",
        kota: [
            "Kota Jambi",
            "Kota Sungai Penuh",
            "Kab. Tebo",
            "Kab. Bungo",
            "Kab. Tanjung Jabung Timur",
            "Kab. Tanjung Jabung Barat",
            "Kab. Muaro Jambi",
            "Kab. Batanghari",
            "Kab. Sarolangun",
            "Kab. Merangin",
            "Kab. Kerinci"
        ]
    },

    {
        provinsi: "Sumatera Selatan",
        kota: [
            "Kota Palembang",
            "Kota Pagar Alam",
            "Kota Lubuk Linggau",
            "Kota Prabumulih",
            "Kab. Musi Rawas Utara",
            "Kab. Penukal Abab Lematang Ilir",
            "Kab. Empat Lawang",
            "Kab. Ogan Ilir ",
            "Kab. Ogan Komering Ulu Selatan ",
            "Kab. Ogan Komering Ulu Timur ",
            "Kab. Banyuasin",
            "Kab. Musi Banyuasin",
            "Kab. Musi Rawas",
            "Kab. Lahat",
            "Kab. Muara Enim",
            "Kab. Ogan Komering Ilir",
            "Kab. Ogan Komering Ulu"
        ]
    },
    {
        provinsi: "Bengkulu",
        kota: [
            "Kota Bengkulu",
            "Kab. Bengkulu Tengah",
            "Kab. Kepahiang ",
            "Kab. Lebong",
            "Kab. Muko Muko",
            "Kab. Seluma",
            "Kab. Kaur",
            "Kab. Bengkulu Utara",
            "Kab. Rejang Lebong",
            "Kab. Bengkulu Selatan"
        ]
    },
    {
        provinsi: "Lampung",
        kota: [
            "Kota Bandar Lampung",
            "Kota Metro",
            "Kab. Pesisir Barat",
            "Kab. Tulangbawang Barat",
            "Kab. Mesuji",
            "Kab. Pringsewu",
            "Kab. Pesawaran",
            "Kab. Way Kanan",
            "Kab. Lampung Timur",
            "Kab. Tanggamus",
            "Kab. Tulang Bawang",
            "Kab. Lampung Barat",
            "Kab. Lampung Utara",
            "Kab. Lampung Tengah",
            "Kab. Lampung Selatan"
        ]
    },
    {
        provinsi: "Kepulauan Bangka Belitung",
        kota: [
            "Kota Pangkal Pinang",
            "Kab. Belitung Timur",
            "Kab. Bangka Barat",
            "Kab. Bangka Tengah",
            "Kab. Bangka Selatan",
            "Kab. Belitung",
            "Kab. Bangka"
        ]
    },
    {
        provinsi: "Kepulauan Riau",
        kota: [
            "Kota Batam",
            "Kota Tanjung Pinang",
            "Kab. Kepulauan Anambas",
            "Kab. Lingga ",
            "Kab. Natuna",
            "Kab. Karimun",
            "Kab. Bintan"
        ]
    },

    {
        provinsi: "DKI Jakarta",
        kota: [
            "Kota Jakarta Timur",
            "Kota Jakarta Selatan",
            "Kota Jakarta Barat",
            "Kota Jakarta Utara",
            "Kota Jakarta Pusat",
            "Kab. Kepulauan Seribu"
        ]
    },
    {
        provinsi: "Jawa Barat",
        kota: [
            "Kota Bandung",
            "Kota Banjar",
            "Kota Tasikmalaya",
            "Kota Cimahi",
            "Kota Depok",
            "Kota Bekasi",
            "Kota Cirebon",
            "Kota Sukabumi",
            "Kota Bogor",
            "Kab. Pangandaran",
            "Kab. Bandung Barat",
            "Kab. Bekasi",
            "Kab. Karawang",
            "Kab. Purwakarta",
            "Kab. Subang",
            "Kab. Indramayu",
            "Kab. Sumedang",
            "Kab. Majalengka",
            "Kab. Cirebon",
            "Kab. Kuningan",
            "Kab. Ciamis",
            "Kab. Tasikmalaya",
            "Kab. Garut",
            "Kab. Bandung",
            "Kab. Cianjur",
            "Kab. Sukabumi",
            "Kab. Bogor"
        ]
    },
    {
        provinsi: "Jawa Tengah",
        kota: [
            "Kota Semarang",
            "Kota Tegal",
            "Kota Pekalongan",
            "Kota Salatiga",
            "Kota Surakarta",
            "Kota Magelang",
            "Kab. Brebes",
            "Kab. Tegal",
            "Kab. Pemalang",
            "Kab. Pekalongan",
            "Kab. Batang",
            "Kab. Kendal",
            "Kab. Temanggung",
            "Kab. Semarang",
            "Kab. Demak",
            "Kab. Jepara",
            "Kab. Kudus",
            "Kab. Pati",
            "Kab. Rembang",
            "Kab. Blora",
            "Kab. Grobogan",
            "Kab. Sragen",
            "Kab. Karanganyar",
            "Kab. Wonogiri",
            "Kab. Sukoharjo",
            "Kab. Klaten",
            "Kab. Boyolali",
            "Kab. Magelang",
            "Kab. Wonosobo",
            "Kab. Purworejo",
            "Kab. Kebumen",
            "Kab. Banjarnegara",
            "Kab. Purbalingga",
            "Kab. Banyumas",
            "Kab. Cilacap"
        ]
    },
    {
        provinsi: "DI Yogyakarta",
        kota: [
            "Kota Yogyakarta",
            "Kab. Sleman",
            "Kab. Gunung Kidul",
            "Kab. Bantul",
            "Kab. Kulon Progo"
        ]
    },
    {
        provinsi: "Jawa Timur",
        kota: [
            "Kota Surabaya",
            "Kota Batu",
            "Kota Madiun",
            "Kota Mojokerto",
            "Kota Pasuruan",
            "Kota Probolinggo",
            "Kota Malang",
            "Kota Blitar",
            "Kota Kediri",
            "Kab. Sumenep",
            "Kab. Pamekasan",
            "Kab. Sampang",
            "Kab. Bangkalan",
            "Kab. Gresik",
            "Kab. Lamongan",
            "Kab. Tuban",
            "Kab. Bojonegoro",
            "Kab. Ngawi",
            "Kab. Magetan",
            "Kab. Madiun",
            "Kab. Nganjuk",
            "Kab. Jombang",
            "Kab. Mojokerto",
            "Kab. Sidoarjo",
            "Kab. Pasuruan",
            "Kab. Probolinggo",
            "Kab. Situbondo",
            "Kab. Bondowoso",
            "Kab. Banyuwangi",
            "Kab. Jember",
            "Kab. Lumajang",
            "Kab. Malang",
            "Kab. Kediri",
            "Kab. Blitar",
            "Kab. Tulungagung",
            "Kab. Trenggalek",
            "Kab. Ponorogo",
            "Kab. Pacitan"
        ]
    },
    {
        provinsi: "Banten",
        kota: [
            "Kota Serang",
            "Kota Cilegon",
            "Kota Tangerang",
            "Kota Tangerang Selatan",
            "Kab. Serang",
            "Kab. Tangerang",
            "Kab. Lebak",
            "Kab. Pandeglang"
        ]
    },
    {
        provinsi: "Bali",
        kota: [
            "Kota Denpasar",
            "Kab. Buleleng",
            "Kab. Karangasem",
            "Kab. Bangli",
            "Kab. Klungkung",
            "Kab. Gianyar",
            "Kab. Badung",
            "Kab. Tabanan",
            "Kab. Jembrana"
        ]
    },
    {
        provinsi: "Nusa Tenggara Barat",
        kota: [
            "Kota Mataram",
            "Kota Bima",
            "Kab. Lombok Utara",
            "Kab. Sumbawa Barat",
            "Kab. Bima",
            "Kab. Dompu",
            "Kab. Sumbawa ",
            "Kab. Lombok Timur",
            "Kab. Lombok Tengah",
            "Kab. Lombok Barat"
        ]
    },
    {
        provinsi: "Nusa Tenggara Timur",
        kota: [
            "Kota Kupang",
            "Kab. Malaka",
            "Kab. Sabu Raijua",
            "Kab. Manggarai Timur",
            "Kab. Sumba Barat Daya",
            "Kab. Sumba Tengah",
            "Kab. Nagekeo",
            "Kab. Manggarai Barat",
            "Kab. Rote Ndao",
            "Kab. Lembata",
            "Kab. Sumba Barat",
            "Kab. Sumba Timur",
            "Kab. Manggarai",
            "Kab. Ngada",
            "Kab. Ende",
            "Kab. Sikka",
            "Kab. Flores Timur",
            "Kab. Alor",
            "Kab. Belu",
            "Kab. Timor Tengah Utara",
            "Kab. Timor Tengah Selatan",
            "Kab. Kupang"
        ]
    },
    {
        provinsi: "Kalimantan Barat",
        kota: [
            "Kota Pontianak",
            "Kota Singkawang",
            "Kab. Kubu Raya",
            "Kab. Kayong Utara",
            "Kab. Sekadau",
            "Kab. Melawi",
            "Kab. Landak",
            "Kab. Bengkayang",
            "Kab. Kapuas Hulu",
            "Kab. Sintang ",
            "Kab. Ketapang",
            "Kab. Sanggau ",
            "Kab. Mempawah",
            "Kab. Sambas"
        ]
    },
    {
        provinsi: "Kalimantan Tengah",
        kota: [
            "Kota Palangkaraya",
            "Kab. Barito Timur",
            "Kab. Murung Raya",
            "Kab. Pulang Pisau",
            "Kab. Gunung Mas",
            "Kab. Lamandau",
            "Kab. Sukamara",
            "Kab. Seruyan",
            "Kab. Katingan",
            "Kab. Barito Utara",
            "Kab. Barito Selatan",
            "Kab. Kapuas",
            "Kab. Kotawaringin Timur",
            "Kab. Kotawaringin Barat"
        ]
    },
    {
        provinsi: "Kalimantan Selatan",
        kota: [
            "Kota Banjarmasin",
            "Kota Banjarbaru",
            "Kab. Balangan",
            "Kab. Tanah Bambu",
            "Kab. Tabalong",
            "Kab. Hulu Sungai Utara",
            "Kab. Hulu Sungai Tengah",
            "Kab. Hulu Sungai Selatan",
            "Kab. Tapin",
            "Kab. Barito Kuala",
            "Kab. Banjar",
            "Kab. Kotabaru",
            "Kab. Tanah Laut"
        ]
    },

    {
        provinsi: "Kalimantan Timur",
        kota: [
            "Kota Samarinda",
            "Kota Bontang",
            "Kota Balikpapan",
            "Kab. Mahakam Ulu",
            "Kab. Penajam Paser Utara",
            "Kab. Kutai Timur",
            "Kab. Kutai Barat",
            "Kab. Berau",
            "Kab. Kutai Kertanegara",
            "Kab. Paser"
        ]
    },

    {
        provinsi: "Kalimantan Utara",
        kota: [
            "Kota Tarakan",
            "Kab. Tana Tidung",
            "Kab. Nunukan",
            "Kab. Malinau",
            "Kab. Bulungan"
        ]
    },
    {
        provinsi: "Sulawesi Utara",
        kota: [
            "Kota Manado",
            "Kota Tomohon",
            "Kota Bitung",
            "Kota Kotamobagu",
            "Kab. Bolaang Mangondow Selatan",
            "Kab. Bolaang Mangondow Timur",
            "Kab. Kepulauan Siau Tagulandang Biaro",
            "Kab. Bolaang Mangondow Utara",
            "Kab. Minahasa Tenggara",
            "Kab. Minahasa Utara",
            "Kab. Minahasa Selatan",
            "Kab. Kepulauan Talaud",
            "Kab. Kepulauan Sangihe",
            "Kab. Minahasa",
            "Kab. Bolaang Mangondow"
        ]
    },
    {
        provinsi: "Sulawesi Tengah",
        kota: [
            "Kota Palu",
            "Kab. Morowali Utara",
            "Kab. Banggai Laut",
            "Kab. Sigi",
            "Kab. Tojo Una-Una",
            "Kab. Parigi Moutong",
            "Kab. Banggai Kepulauan",
            "Kab. Morowali",
            "Kab. Buol",
            "Kab. Toli-Toli",
            "Kab. Donggala",
            "Kab. Poso",
            "Kab. Banggai"
        ]
    },
    {
        provinsi: "Sulawesi Selatan",
        kota: [
            "Kota Makasar",
            "Kota Palopo",
            "Kota Pare Pare",
            "Kab. Toraja Utara",
            "Kab. Luwu Timur",
            "Kab. Luwu Utara",
            "Kab. Tana Toraja",
            "Kab. Luwu",
            "Kab. Enrekang",
            "Kab. Pinrang",
            "Kab. Sidenreng Rappang",
            "Kab. Wajo",
            "Kab. Soppeng",
            "Kab. Barru",
            "Kab. Pangkajene Kepulauan",
            "Kab. Maros",
            "Kab. Bone",
            "Kab. Sinjai",
            "Kab. Gowa",
            "Kab. Takalar",
            "Kab. Jeneponto",
            "Kab. Bantaeng",
            "Kab. Bulukumba",
            "Kab. Kepulauan Selayar"
        ]
    },
    {
        provinsi: "Sulawesi Tenggara",
        kota: [
            "Kota Kendari",
            "Kota Bau Bau",
            "Kab. Buton Selatan",
            "Kab. Buton Tengah",
            "Kab. Muna Barat",
            "Kab. Konawe Kepulauan",
            "Kab. Kolaka Timur",
            "Kab. Buton Utara",
            "Kab. Konawe Utara",
            "Kab. Kolaka Utara",
            "Kab. Wakatobi",
            "Kab. Bombana",
            "Kab. Konawe Selatan",
            "Kab. Buton",
            "Kab. Muna",
            "Kab. Konawe",
            "Kab. Kolaka"
        ]
    },
    {
        provinsi: "Gorontalo",
        kota: [
            "Kota Gorontalo",
            "Kab. Pohuwato",
            "Kab. Bone Bolango",
            "Kab. Boalemo",
            "Kab. Gorontalo",
            "Kab. Gorontalo Utara"
        ]
    },
    {
        provinsi: "Sulawesi Barat",
        kota: [
            "Kab. Majene",
            "Kab. Polowali Mandar",
            "Kab. Mamasa",
            "Kab. Mamuju",
            "Kab. Mamuju Utara",
            "Kab. Mamuju Tengah"
        ]
    },

    {
        provinsi: "Maluku",
        kota: [
            "Kota Ambon",
            "Kota Tual",
            "Kab. Buru Selatan",
            "Kab. Maluku Barat Daya",
            "Kab. Kepulauan Aru",
            "Kab. Seram Bagian Barat ",
            "Kab. Seram Bagian Timur",
            "Kab. Buru",
            "Kab. Maluku Tenggara Barat",
            "Kab. Maluku Tenggara",
            "Kab. Maluku Tengah"
        ]
    },
    {
        provinsi: "Maluku Utara",
        kota: [
            "Kota Ternate",
            "Kota Tidore Kepulauan",
            "Kab. Pulau Taliabu",
            "Kab. Pulau Morotai",
            "Kab. Halmahera Timur",
            "Kab. Kepulauan Sula",
            "Kab. Halmahera Selatan",
            "Kab. Halmahera Utara",
            "Kab. Halmahera Tengah",
            "Kab. Halmahera Barat"
        ]
    },
    {
        provinsi: "Papua",
        kota: [
            "Kota Jayapura",
            "Kab. Deiyai",
            "Kab. Intan Jaya",
            "Kab. Dogiyai",
            "Kab. Puncak",
            "Kab. Nduga",
            "Kab. Lanny Jaya",
            "Kab. Yalimo",
            "Kab. Mamberamo Tengah",
            "Kab. Mamberamo Raya",
            "Kab. Supiori",
            "Kab. Asmat",
            "Kab. Mappi",
            "Kab. Boven Digoel",
            "Kab. Waropen",
            "Kab. Tolikara",
            "Kab. Yahukimo",
            "Kab. Pegunungan Bintang",
            "Kab. Keerom",
            "Kab. Sarmi",
            "Kab. Mimika",
            "Kab. Paniai",
            "Kab. Puncak Jaya",
            "Kab. Biak Numfor",
            "Kab. Kepulauan Yapen",
            "Kab. Nabire",
            "Kab. Jayapura",
            "Kab. Jayawijaya",
            "Kab. Merauke"
        ]
    },
    {
        provinsi: "Papua Barat",
        kota: [
            "Kota Sorong",
            "Kab. Pegunungan Arfak",
            "Kab. Manokwari Selatan",
            "Kab. Maybrat",
            "Kab. Tambrauw",
            "Kab. Kaimana",
            "Kab. Teluk Wondama",
            "Kab. Teluk Bintuni",
            "Kab. Raja Ampat",
            "Kab. Sorong Selatan",
            "Kab. Fak Fak",
            "Kab. Manokwari",
            "Kab. Sorong"
        ]
    }
]

export const courseClassList = [
    {
        label: 'Free Course: Introduction to IT Programming',
        value: 1,
    },
    {
        label: 'Workshop: Coding Kickstart',
        value: 2,
    },
    {
        label: 'Bootcamp',
        value: 3,
    }
]

export const freeCourseList = [
    // Commented for hiding the option.
    // {
    //     label: 'Video Game',
    //     value: 1,
    // },
    {
        label: 'Front-End Website',
        value: 2,
    },
    {
        label: 'Back-End Website',
        value: 3,
    },
    // Commented for hiding the option.
    // {
    //     label: 'Mobile Application',
    //     value: 4
    // }
]

export const workshopCourseList = [
    // Commented for hiding the option.
    // {
    //     label: 'Video Game',
    //     value: 5,
    // },
    {
        label: 'Front-End Website',
        value: 6,
    },
    {
        label: 'Back-End Website',
        value: 7,
    },
    // Commented for hiding the option.
    // {
    //     label: 'Mobile Application',
    //     value: 8
    // }
]

export const bootcampCourseList = [
    {
        label: 'Full Stack Engineer',
        value: 9
    }
]

export const freeVideoGameScheduleList = [
    {
        label: '8 Desember 2023 pukul 19.00 - selesai',
        value: 43
    },
    {
        label: '14 Desember 2023 pukul 19.00 - selesai',
        value: 44
    },
    {
        label: '21 Desember 2023 pukul 19.00 - selesai',
        value: 45
    },
]

export const freeFrontEndWebsiteScheduleList = [
    {
        label: '1 Maret 2024 pukul 19.00 - selesai',
        value: 68
    },
]

export const freeBackEndWebsiteScheduleList = [
    {
        label: '22 Februari 2024 pukul 19.00 - selesai',
        value: 69
    },
    {
        label: '26 Februari 2024 pukul 19.00 - selesai',
        value: 70
    },
]

export const freeMobileApplicationScheduleList = [
    {
        label: '12 Desember 2023 pukul 19.00 - selesai',
        value: 52
    },
    {
        label: '13 Desember 2023 pukul 19.00 - selesai',
        value: 53
    },
    {
        label: '19 Desember 2023 pukul 19.00 - selesai',
        value: 54
    },
]

export const workshopVideoGameScheduleList = [
    {
        label: '11 dan 12 Januari 2024 pukul 18.30 - 21.30',
        value: 55
    },
    {
        label: '18 dan 19 Januari 2024 pukul 18.30 - 21.30',
        value: 56
    },
    {
        label: '25 dan 26 Januari 2024 November pukul 18.30 - 21.30',
        value: 57
    },
]

export const workshopFrontEndWebsiteScheduleList = [
    {
        label: '22 dan 23 April 2024 pukul 18.30 - selesai',
        value: 71
    },
]

export const workshopBackEndWebsiteScheduleList=[
    {
        label: '13 dan 14 Maret 2024 pukul 18.30 - 21.30',
        value: 72
    },
    {
        label: '20 dan 21 Maret 2024 pukul 18.30 - 21.30',
        value: 73
    },
]

export const workshopMobileApplicationScheduleList = [
    {
        label: '3 dan 4 Januari 2024 pukul 18.30 - 21.30',
        value: 64
    },
    {
        label: '10 dan 17 Januari 2024 pukul  18.30 - 21.30',
        value: 65
    },
    {
        label: '30 dan 31 Januari 2024 pukul 18.30 - 21.30',
        value: 66
    },
]

export const bootcampFullStackEngineerScheduleList = [
    {
        label: 'Mulai Maret 2024',
        value: 67
    }
]
